
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../configs/constants'
import '../style.module.scss';
import { IMAGES_ICON } from '../../../assets/images/exportImages';
import { GetContactUsDetails, GetSocialMediaLink } from '../../../models/model'
import { useEffect, useState } from 'react';
import { useStateValue } from '../../../providers/StateProvider';

export default function Footer() {
    const [{ user }]: any = useStateValue();
    const [_contactusList, _setcontactusList] = useState<any>([]);
    const navigate = useNavigate();
    const domain = "chinnappaganesan.cartyogi.com";

    useEffect(() => {      
        if (user) {
            GetSocialMediaList();
            Getcontact();
        }
    }, [user])

    const Getcontact = () => {
        GetContactUsDetails(domain)
            .then((resp) => {
                console.log(resp.data)
                _setcontactusList([...resp?.data])
            })
            .catch((err) => console.log(err))
    }

    const GetSocialMediaList = () => {
        GetSocialMediaLink()
            .then((resp) => {
                _setcontactusList([...resp?.data])
            })
            .catch((err) => console.log(err))
    }



    return (<>
        <div className='bg-primary text-white'>
            <div className='container py-4'>
                <div className='row justify-content-center'>
                    <div className="text-center fs-3 my-4">மோடியின் தமிழகம்</div>
                    <div className="text-center fs-5">Quick Links</div>
                    <div className="col-md-8 my-3 p-3 d-flex align-items-center justify-content-around flex-wrap gap-3" style={{ backgroundColor: "#08316e", borderRadius: "50rem" }}>
                        <div className="" role="button">Login</div>
                        <div className="" role="button">About Writer</div>
                        <div className="" role="button">Press Coverage</div>
                        <div className="" role="button">Testimonials</div>
                    </div>
                </div>
                <div className="mx-auto col-md-8 d-flex justify-content-between flex-wrap">
                    <div className="">
                        <div className="my-2 fs-5">Contact us:</div>
                        <div className="d-flex gap-3 mt-2 flex-wrap">
                            <div className="d-flex gap-2">
                                <div className=""><img src={IMAGES_ICON.CallIcon} height={20} alt="Icon" draggable={false} /></div>
                                {_contactusList?.map((item: any, index: number) =>
                                    <div className="">{item.PhoneNo}</div>
                                )}

                            </div>
                            <div className="d-flex gap-2 flex-wrap">
                                <div className=""><img src={IMAGES_ICON.MailIcon} height={18} alt="Icon" draggable={false} /></div>
                                {_contactusList?.map((item: any, index: number) =>
                                    <div className="">{item.EmailId}</div>
                                )}

                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="my-2 fs-5">Follow us on:</div>
                        <div className="d-flex gap-3 flex-wrap">
                            <div className=""><img src={IMAGES_ICON.FacebookIcon} height={48} alt="Icon" draggable={false} /></div>
                            <div className=""><img src={IMAGES_ICON.TwitterIcon} height={48} alt="Icon" draggable={false} /></div>
                            <div className=""><img src={IMAGES_ICON.YoutubeIcon} height={48} alt="Icon" draggable={false} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerBg py-2">
                <div className="container">
                    <div className='mt-2 text-center text-dark'>Copyright 2024 &#169;  <img src={IMAGES_ICON.FooterIcon} alt="Icon" /> All rights reserved</div>
                </div>
            </div>
        </div>
    </>)
}
