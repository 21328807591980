import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Button, CssBaseline, IconButton, InputAdornment } from '@mui/material'
import { Email, FiberManualRecord, HighlightOff, RestartAlt, WidthFullRounded } from '@mui/icons-material'
import { ROUTES } from '../../configs/constants';
import { IMAGES_ICON } from '../../assets/images/exportImages';
import Footer from './indcludes/footer';
import { Dialog, DialogContent, TextField } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from "react";
import { LoadingButton } from '@mui/lab';
import clsx from 'clsx';
import Swal from "sweetalert2";
import { useStateValue } from '../../providers/StateProvider';
import { validateEmail, validateMobile } from '../../services/ValidationService';
import { GetAllSubCategory, MemberDetails, getLoginOtp, verifyLoginOtp } from '../../models/model';
import { base64, textFieldStyle } from '../../services/HelperService';
import Countdown from '../../components/helpers/Countdown';
import { UserDetails } from '../../services/AuthService';

export default function DashboardLayout() {
    const handleClose = () => {
        setOpen(false);
    };
    const [email, setEmailId] = useState<any>();
    const [open, setOpen] = useState(false);
    const [_memberDet, _setmemberDetails] = useState<any>();
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen11, setIsOpen11] = useState(false);
    const [cartItemCount, _setcartItemCount] = useState<any>();
    const [_buttonLoading, _setbuttonLoading] = useState<any>(false);

    const navigate = useNavigate();
    const [{ cartItems,Email }, dispatch]: any = useStateValue();
    const [_formData, _setFormData] = useState<any>({ emailId: '' })
    const [_loading, _setLoading] = useState<any>(false)
    const validate = {
        emailId: { error: false, message: '' },
        otp: { error: false, message: '' },
    }
    const [_validate, _setValidate] = useState<any>(validate);
    const [_otpSent, _setOtpSent] = useState<any>(false);
    const [_otp, _setOtp] = useState<any>('')
    const [_otpResend, _setOtpResend] = useState<any>('')
    const [_catogoryList, _setAllCatogoryList] = useState<any>();
    const [_name, _setName] = useState<any>();
    const [otpVerified, setOtpVerified] = useState(false);

    const onFormChange = (key: string, value: any) => {
        _setFormData({ ..._formData, [key]: value })
    }


    const timeUpFirst = () => {
        _setOtpResend(true);
    }

    const openCart = () => {
        setIsOpen1(true);
    }

    const closeCart = () => {
        setIsOpen1(false);
    }

    // const Get_ALLCatogory = () => {
    //     GetAllSubCategory(4, 0)
    //         .then(res => {
    //             _setAllCatogoryList(res.data[0]);
    //             console.log(res.data[0]);
    //             _setName(res.data[0]?.SubCategoryName)
    //         })
    // }


    const checkout = () => {

        // const Quantity: any = localStorage.getItem('_Cartquantity');
        // const res: any = (Quantity * (_catogoryList?.ProDetail[0]?.ProdVariance[0]?.VariancePrice));
        // const name: any = _catogoryList?.SubCategoryName;
        // const imgUrl: any = _catogoryList?.ProDetail[0]?.Image1[0]?.ImageURL;
        // const productId: any = _catogoryList?.ProDetail[0]?.ProductId;
        // const taxDetId: any = _catogoryList?.ProDetail[0]?.TaxDetailsId;
        // const productVarianceId: any = _catogoryList?.ProDetail[0]?.ProdVariance[0].ProductVarianceId;
        // const variancePrice: any = _catogoryList?.ProDetail[0]?.ProdVariance[0]?.VariancePrice;
        // const varianceType: any = _catogoryList?.ProDetail[0]?.ProdVariance[0].VarianceType;
        // const suCatagoryId: any = _catogoryList?.SubCategoryId;
        // const valuesToSet = [
        //     { key: "_totalPrice", value: res },
        //     { key: "_quantity", value: Quantity },
        //     { key: "_Productname", value: name },
        //     { key: "_imageUrl", value: imgUrl },
        //     { key: "_productId", value: productId },
        //     { key: "_taxDetailsId", value: taxDetId },
        //     { key: "_productVarianceId", value: productVarianceId },
        //     { key: "_productPrice", value: variancePrice },
        //     { key: "_varianceType", value: varianceType },
        //     { key: "_subcatogoryId", value: suCatagoryId },
        // ];

        // valuesToSet.forEach(({ key, value }) => {
        //     localStorage.setItem(key, value);
        //     dispatch({
        //         type: "SET_Value",
        //         key: key,
        //         value: value
        //     });
        // });
        setIsOpen1(false);
        navigate(ROUTES.DASHBOARD.CHECKOUT_PAGE)
    }

    const clearAll = () => {
        _setOtpSent(false);
        _setOtp('');
        _setOtpResend(false)
        _setFormData({ ..._formData, emailId: '' });
    }

    useEffect(() => {
debugger;
        if(Email!=null){
            setEmailId(Email);
        }
    },[Email,email])

    const checkValidation = () => {
        const validation: any = Object.assign({}, validate);
        let isValid = true;
        if (!_formData?.emailId) {
            validation.emailId.error = true;
            validation.emailId.message = 'Required Field';
            isValid = false;
        }

        if (_formData?.emailId && isNaN(_formData?.emailId) && !validateMobile(_formData?.emailId)) {
            validation.emailId.error = true;
            validation.emailId.message = 'Enter valid email address';
            isValid = false;
        }
        _setValidate(validation)
        return isValid;
    }

    const handleSendOtp = () => {
        _setLoading(true);
        if (!checkValidation()) {
            _setLoading(false);
            return;
        } else {
            getLoginOtp(_formData?.emailId)
                .then((resp) => {
                    _setOtpSent(true)
                    _setOtpResend(false)
                    setOtpVerified(false);
                })
                .catch((err) => {
                    if (err?.response?.data?.statusCode === 400) {
                        const validation: any = Object.assign({}, validate);
                        validation.emailId.error = true;
                        if (isNaN(_formData?.emailId)) {
                            validation.emailId.message = err?.response?.data?.data || 'Please enter valid Mobile Number';
                        }
                        _setValidate(validation)
                    }
                })
                .finally(() => _setLoading(false))
        }
    }

    const Logout = () => {
        debugger;
        localStorage.removeItem('app_login');
        dispatch({
            type: 'SET_USEREMAIL',
            MemberDet: null,
        });
        setEmailId(null)
        _setOtpSent(false);
        setOtpVerified(false);
        localStorage.removeItem("MemberId"); 
        _setOtp('');
        
        // navigate(ROUTES.DASHBOARD.HOME)

    }

    const handleVerifyOtp = () => {
        verifyLoginOtp(_formData?.emailId, _otp)
            .then((resp) => {
                var num = "";
                if (resp?.data === 'success') {


debugger;
                        localStorage.setItem("app_login",base64.encode(JSON.stringify({Email:_formData?.emailId})));
                        const _tempData = UserDetails.userDet();
                        dispatch({
                            type: "SET_USEREMAIL",
                            Email: _tempData
                        });
                        setEmailId(_formData?.emailId)
                        // userDet(_tempData?.Email);
                        handleClose();
                        //navigate(ROUTES.DASHBOARD.HOME)
                        Swal.fire({
                            // title: 'Success!',
                            text: 'Login Successfully',
                            // icon: 'success',
                            confirmButtonText: 'OK',
                        });

                    // });

                    //setTimeout(() => {
                        
                    //}, 1000)
                }
                else {
                    _setOtp("");
                    setOtpVerified(false);
                    handleClose();
                    Swal.fire({
                        // title: 'Success!',
                        text: 'Invalid Otp',
                        // icon: 'warning',
                        confirmButtonText: 'OK',
                    });
                }
            })
            .catch((err) => {
                if (err?.response?.status === 400) {
                    const validation: any = Object.assign({}, validate);
                    validation.otp.error = true;
                    validation.otp.message = 'Wrong OTP' || '';
                    _setValidate(validation)
                }
            })
            .finally(() => _setLoading(false))
    }

    // const userDet = (_getnumber:any) => {
    //     MemberDetails(_getnumber).then(res => {
    //         localStorage.setItem("app_login",base64.encode(JSON.stringify({MemberId:res?.data[0]?.MemberId})));
    //         const _tempData = UserDetails.userDet();
    //         dispatch({
    //             type: "SET_USEREMAIL",
    //             MemberId: _tempData?.MemberId
    //         });
    //         _setmemberDetails(res?.data[0]?.MemberId);
    //         console.log(res?.data[0]?.MemberId)
    //     })
    // }

    useEffect(() => {
        const quantity: any = localStorage.getItem('_Cartquantity');
        var name=localStorage.getItem("_Productname");
        if (quantity !== null) {
            _setcartItemCount(quantity);
            _setName(name);
        }
        if (_otp.length === 4 && !otpVerified) {
            handleVerifyOtp();
            setOtpVerified(true);
        }
    }, [_otp,cartItemCount,otpVerified,checkout])


    useEffect(()=>{
        debugger;
        if(cartItems!=null){
            var vData=cartItems?.Quantity? cartItems?.Quantity:0;
            _setcartItemCount(vData);
        }
    },[cartItems])

    const resetTree = () => {
        navigate(ROUTES.DASHBOARD.HOME)

    }

    const cartClick=()=>{
        debugger;
        var name=localStorage.getItem("_Productname");
        _setName(name);
        setIsOpen1(false)
    }

    return <React.Fragment>
        <CssBaseline />
        <section className='bg-primary'>
            <div className="container">
                <div className="px-4 bg-primary text-white d-flex flex-wrap justify-content-between align-items-center p-2 mobJustify">
                    <div className="my-2 fs-4" style={{ cursor: "pointer" }} onClick={(resetTree)}>மோடியின் தமிழகம்</div>
                    {/* <div className="my-2 text-center"><span role="button" onClick={resetTree} className="px-3 py-1 rounded bg-secondary text-white d-flex align-items-center">Reset <RestartAlt className='ms-2 text-white' /></span></div> */}
                    <div className="my-2 d-flex gap-3 align-items-center mobJustify">

                        {
                            email == null &&
                            <div className='p-1'>
                                <span className="loginButton loginHover" role="button" onClick={() => setOpen(true)}>Login</span>
                            </div>
                        }
                        {
                            email != null &&
                            <div className='p-1'>
                                <span className="loginButton loginHover" role="button" onClick={() => Logout()}>Logout</span>
                            </div>
                        }

                        <div>
                            <Dialog fullWidth maxWidth="xs" open={isOpen1}>
                             <DialogContent>
                            <div className="justify-content-center">

                            <h6 className="text-center" ><b>Checkout</b></h6>
                           <IconButton className="p-0" style={{ position: 'absolute', right: "8px", top: "5px" }} aria-label="close" onClick={() =>cartClick() }>< HighlightOff /></IconButton>
                            <div className="row gx-0 mt-2">
                            
                             <div className="row gx-0 mb-2">
                                 <div className="px-2">
                                     <p className="mt-2 text-center" style={{ fontSize: "15px" }} > Quantity : {cartItemCount} </p>
                                     <p className="mt-2 text-center" style={{ fontSize: "15px" }} > Product Name  : {_name} </p>
                                 </div>
                             </div>
                             <div className="d-flex justify-content-center mb-2 mt-2">
                                 <Button variant={"contained"} type={"button"} className={clsx('bg-warning', 'px-4', 'py-1', 'w-25', 'text-dark')} onClick={checkout}>checkout</Button>
                             </div>
                                    </div>
                                     
                                </div>
                              
                            </DialogContent>
                        </Dialog> 
                        </div>
                        <div className='top-right'>
                            <Dialog open={open} onClose={handleClose}>
                                <DialogContent>
                                    <div className="col-md-12 d-flex justify-content-between">
                                        <span className="text-primary text-center fs-6">Login</span>
                                        <CancelIcon className="text-primary" style={{ cursor: "pointer" }} onClick={handleClose} />
                                    </div>
                                    <form noValidate>
                                        <div className="my-2">
                                            <TextField label="" size="small" placeholder='Mobile No.' fullWidth variant='outlined' value={_formData?.emailId} onChange={(e: any) => !_otpSent && onFormChange('emailId', e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            {!_otpSent && <div style={{ cursor: "pointer" }}>
                                                                <small role="button" className={`${!_loading ? 'bg-dark text-white' : 'bg-light text-muted'} p-2 rounded`} onClick={() => !_loading && handleSendOtp()}>Send OTP</small>
                                                            </div>}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{ ...textFieldStyle, }} error={_validate.emailId.error} helperText={_validate.emailId.message} />
                                        </div>

                                        {_otpSent && <div className="mt-4">
                                            <div className="fw600">Enter OTP</div>
                                            <TextField fullWidth label=" " value={_otp} onChange={(e: any) => _setOtp(e.target.value.slice(0, 7))} type="text" size="small"
                                                sx={{ ...textFieldStyle, }} InputLabelProps={{ shrink: false }}
                                                inputProps={{
                                                    style: { fontSize: 17, letterSpacing: 42, textAlign: 'center' },
                                                }}
                                                error={_validate.otp.error} helperText={_validate.otp.message}
                                            />
                                            <div className="mt-2 d-flex align-items-center justify-content-between flex-wrap">
                                                {!_otpResend ? <Countdown min={0} sec={60} timeUp={timeUpFirst} /> : <div></div>}
                                                {_otpResend ? <div className="linkCreate fw600" onClick={handleSendOtp} role="button">Resend OTP</div> : <div></div>}
                                            </div>
                                        </div>}
                                    </form>

                                    {/* {_otpSent &&
                                        <div className="mt-3 text-center">
                                            <small role="button" className={`${!_loading ? 'bg-dark text-white' : 'bg-light text-muted'} p-2 rounded`}
                                                onClick={(handleVerifyOtp)}>Submit</small>

                                        </div>
                                    } */}

                                </DialogContent>
                            </Dialog>
                        </div>
                        <div className='p-1' style={{ position: 'relative', cursor: 'pointer' }} onClick={openCart}>
                            <img src={IMAGES_ICON.MyCartIcon} alt="" draggable={false} height={38}/>
                            {cartItemCount > 0 && (
                                <div style={{
                                    position: 'absolute',
                                    top: '-1px',
                                    right: '-1px',
                                    background: '#ff0000',
                                    color: '#fff',
                                    borderRadius: '50%',
                                    width: '20px',
                                    height: '20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    {cartItemCount}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <main>
            <Outlet />
        </main>
        <Footer />
    </React.Fragment >

}
