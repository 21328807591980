import { useLocation } from "react-router-dom";
import React from "react";
import moment from "moment";
import 'moment-timezone';


export const userSession = (withToken = false) => {
    const _token: string | null = localStorage.getItem('_token');

    if (_token === null) {
        return null;
    }

    try {
        const user:any = _token;
        const response = {
            accessToken: user,
        };

        if (withToken) {
            return response;
        }

        return response;
    } catch (error) {
        return null;
    }

};

export const cartSession = () => {
    debugger;
    const _cartItems: any = localStorage.getItem('Quantity');
    try{
        const items:any = JSON.parse(_cartItems);
        const response = {
            Quantity:items.Quantity,
            name:items.name
        }
        return response;
    }
    catch (error) {
        return null;
    }
}


export const validationScroll = (key: string, type: ScrollLogicalPosition = 'center') => {
    let scrollId: HTMLElement | null = document.getElementById(key);
    if (scrollId) {
        scrollId.style.display = 'block';
        scrollId.scrollIntoView({ behavior: 'smooth', block: type });
        scrollId.focus();
    } else {
        console.error(`Element with id "${key}" not found in the DOM.`);
    }
}

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function EncodeUnicode(str: any) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
}

// Decoding base64 ⇢ UTF8

export function DecodeUnicode(str: any) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
}

// Get Milliseconds from time frame

export function getMilliseconds(timeString: any) {
    const format = 'DD-MM-YYYY HH:mm:ss';
    const dateTime = moment(timeString, format);
    const milliseconds = dateTime.valueOf();
    return milliseconds;
}


// Date format in DD/MM/YYYY
export const formatDateTime = (date: any) => {
    let dateReturn = '-';
    try {
        if (date !== '' && date !== null) {
            const _date = moment.tz(date, moment.tz.guess()).toDate();
            dateReturn = moment(_date).format('DD-MMM-YYYY hh:mm:ss A');
        }
    } catch (e) {
        dateReturn = '-';
    }

    return dateReturn;
}

export const formatDate = (date: any) => {
    let dateReturn = '-';
    try {
        if (date !== '' && date !== null) {
            const _date = moment.tz(date, moment.tz.guess()).toDate();
            dateReturn = moment(_date).format('DD-MMM-YYYY');
        }
    } catch (e) {
        dateReturn = '-';
    }

    return dateReturn;
}

export function generateUniqueId() {
    const timestamp = new Date().getTime().toString(16); // Convert current time to hexadecimal string
    const randomPart = Math.random().toString(16).slice(2); // Extract a random part from the random number
    return timestamp + randomPart;
  }


export const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: '2px solid #dcdcdc',
        },
        '&:hover fieldset': {
            borderColor: '#B2BAC2',
        },
        '&.Mui-focused fieldset': {
            border: '2px solid #000'
        },
    },
    '& .MuiInputBase-input': {
        fontFamily: 'Poppins, sans-serif !important'

    },
    '& .MuiInputLabel-outlined': {
        backgroundColor: '#fff !important',
        paddingRight: '0.25rem !important',
    },
    '& label.Mui-focused': {
        fontFamily: 'Poppins, sans-serif !important',
        color: '#000 !important',
    },
    'label + &': {
        fontFamily: 'Poppins, sans-serif !important'
    },
}

export const base64 = {
    encode(text: string) {
        return btoa(text);
    },
    decode(text: string) {
        return atob(text);
    }
}
