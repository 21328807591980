import { Button, Checkbox, MenuItem, TextField, Typography } from "@mui/material"
import clsx from "clsx"
import { useCallback, useEffect, useState } from "react";
import { GetCompanyDetails, GetPG_COnfiguration, InsrtSalesOrder, GetInvoiceNumber, MemberDetails, RazorPay, updateMemberDet, SendInvoiceMail, InsrtSalesOrderItem, BindOrderDetails, GetStateList,
    EditMemberProfile } from "../../models/model";
import Swal from "sweetalert2";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { useStateValue } from "../../providers/StateProvider";
import { Route, useNavigate } from "react-router-dom";
import { ROUTES } from "../../configs/constants";
import { validationScroll } from "../../services/HelperService";
import { validateEmail, validateMobile } from "../../services/ValidationService";
import { UserDetails,cartQuanty } from "../../services/AuthService";
import { base64 } from './../../services/HelperService';


export default function CheckoutPage() {
    const [{ Email }, dispatch]: any = useStateValue();
    const [_totalPrice, _setTotalPrice] = useState<any>();
    const navigate = useNavigate();
    const [_quantity, _seQuantity] = useState<any>();
    const [_memberid, _setMemberId] = useState<any>();
    const [_companyDet, _setCompanyDet] = useState<any>();
    const [_invoiceNo, _setInvoiceNum] = useState<any>();
    const [_name, _setName] = useState<any>();
    const [_memberDet, _setmemberDetails] = useState<any>();
    const [_pgConfig, _setPgConfig] = useState<any>();
    const [_pgSecret, _setPgSecret] = useState<any>();
    const [checked, setChecked] = useState(false);
    const [email, setEmailId] = useState<any>();
    const [orderId, _setOrderId] = useState<any>();
    const [_productId, _setproductId] = useState<any>();
    const [_taxDeatailsId, _settaxDeatailsId] = useState<any>();
    const [_imgUrl, _setimgUrl] = useState<any>();
    const [_productVarianceId, _setProductVarianceId] = useState<any>();
    const [_variancePrice, _setvariancePrice] = useState<any>()
    const [_varianceType, _setVarianceType] = useState<any>()
    const [Razorpay, isLoaded] = useRazorpay();
    const [_productOrderDetails, _setproductOrderDetails] = useState<any>()
    const [_subcatogoryId, _setsucatogoryId] = useState<any>()
    const [_stateList, _setStateList] = useState<any>([])
    const [_delCharge, _setdelCharge] = useState<any>(0);

    const validate = {
        AddressLine1: { error: false, message: '' },
        AddressLine2: { error: false, message: '' },
        AddressLine11: { error: false, message: '' },
        AddressLine21: { error: false, message: '' },
        City: { error: false, message: '' },
        City1: { error: false, message: '' },
        ContactNo: { error: false, message: '' },
        EmailId: { error: false, message: '' },
        ContactNo1: { error: false, message: '' },
        EmailId1: { error: false, message: '' },
        MemberName: { error: false, message: '' },
        MemberName1: { error: false, message: '' },
        PinCode: { error: false, message: '' },
        PinCode1: { error: false, message: '' },
        State: { error: false, message: '' },
        State1: { error: false, message: '' },
    }

    const [_validate, _setValidate] = useState<any>(validate);

    const checkValidation = () => {
        const validation = Object.assign({}, validate);
        let isValid = true;
        if (!postData?.ContactNo1) {
            validationScroll('ContactNo1')
            validation.ContactNo1.error = true;
            validation.ContactNo1.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.EmailId1) {
            validationScroll('EmailId1')
            validation.EmailId1.error = true;
            validation.EmailId1.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.MemberName1) {
            validationScroll('MemberName1')
            validation.MemberName1.error = true;
            validation.MemberName1.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.State1) {
            validationScroll('State1')
            validation.State1.error = true;
            validation.State1.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.State) {
            validationScroll('State')
            validation.State.error = true;
            validation.State.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.MemberName) {
            validationScroll('MemberName')
            validation.MemberName.error = true;
            validation.MemberName.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.ContactNo) {
            validationScroll('ContactNo')
            validation.ContactNo.error = true;
            validation.ContactNo.message = 'Required Field';
            isValid = false;
        }

        if (!postData?.EmailId) {
            validationScroll('EmailId')
            validation.EmailId.error = true;
            validation.EmailId.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.AddressLine1) {
            validationScroll('AddressLine1')
            validation.AddressLine1.error = true;
            validation.AddressLine1.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.AddressLine2) {
            validationScroll('AddressLine2')
            validation.AddressLine2.error = true;
            validation.AddressLine2.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.AddressLine11) {
            validationScroll('AddressLine11')
            validation.AddressLine11.error = true;
            validation.AddressLine11.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.AddressLine21) {
            validationScroll('AddressLine21')
            validation.AddressLine21.error = true;
            validation.AddressLine21.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.PinCode1) {
            validationScroll('PinCode1')
            validation.PinCode1.error = true;
            validation.PinCode1.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.PinCode) {
            validationScroll('PinCode')
            validation.PinCode.error = true;
            validation.PinCode.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.City) {
            validationScroll('City')
            validation.City.error = true;
            validation.City.message = 'Required Field';
            isValid = false;
        }
        if (!postData?.City1) {
            validationScroll('City1')
            validation.City1.error = true;
            validation.City1.message = 'Required Field';
            isValid = false;
        }
        if (postData?.EmailId && !validateEmail(postData?.EmailId)) {
            validationScroll('EmailId')
            validation.EmailId.error = true;
            validation.EmailId.message = 'Enter valid email address';
            isValid = false;
        }
        if (postData?.ContactNo && !validateMobile(postData?.ContactNo)) {
            validationScroll('ContactNo')
            validation.ContactNo.error = true;
            validation.ContactNo.message = 'Enter valid mobile number';
            isValid = false;
        }
        if (postData?.EmailId1 && !validateEmail(postData?.EmailId1)) {
            validationScroll('EmailId1')
            validation.EmailId1.error = true;
            validation.EmailId1.message = 'Enter valid email address';
            isValid = false;
        }
        if (postData?.ContactNo1 && !validateMobile(postData?.ContactNo1)) {
            validationScroll('ContactNo1')
            validation.ContactNo1.error = true;
            validation.ContactNo1.message = 'Enter valid mobile number';
            isValid = false;
        }
        _setValidate(validation)
        return isValid;
    }


    const handlePayment = useCallback(async (orderId: any) => {
        debugger;
        var num="";
        if(_memberid===null || _memberid===""){
            MemberDetails(Email.Email).then(res => {
                num = res?.data[0]?.MemberId;                    
            })
        }
        else{
            num=_memberid;
        }
        const options: RazorpayOptions = {
            key: _pgConfig,
            amount: (Number(_totalPrice)+(Number(_quantity)*Number(_delCharge))).toString(),
            currency: "INR",
            name: _name,
            description: "",
            image: "",
            order_id: orderId,
            handler: (res: any) => {
                debugger;

                if (res?.razorpay_order_id !== null && res?.razorpay_payment_id !== null && res?.razorpay_signature !== null) {
                    //Success
                    _insertSalesOrder(res?.razorpay_payment_id,num);
                }
                else {
                    //Failed
                }
            },
            prefill: {
                name: "",
                email: "",
                contact: "",
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };
        const rzpay = new Razorpay(options);
        rzpay.open();
    }, [Razorpay, _totalPrice, _name, orderId, _pgConfig]);


    useEffect(() => {
        debugger;
        var num="";
        if( Email?.Email!=null){
            setEmailId(Email?.Email)
                MemberDetails(Email?.Email).then(res => {
                    debugger;
                num = res?.data[0]?.MemberId;    
                _setMemberId(res?.data[0]?.MemberId);  
                localStorage.setItem("MemberId",num);     
            })
        }
    }, [Email])


    useEffect(() => {
        if (isLoaded) {
        }
    }, [isLoaded, handlePayment])

    const _insertSalesOrder = (raz_paymentId: any, memberId : any) => {
        const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        const length = 5;
        let randomNo = '';
        for (let i = 0; i < length; i++) {
            randomNo += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        const postrecords = localStorage.getItem('postData');
        var postDataObject;
        if (postrecords !== null) {
            postDataObject = JSON.parse(postrecords);
            _setpostData(postDataObject);
        }
        var vDeliveryCharge=localStorage.getItem('deliveryCharges');
        if(memberId===undefined || memberId===null || memberId==="")
        {
            memberId=localStorage.getItem("MemberId"); 
        }
        InsrtSalesOrder("chinnappaganesan.cartyogi.com", "Web", "Online",
            _companyDet?.PhoneNo,_totalPrice, 0, (Number(_quantity)*Number(vDeliveryCharge)), ((Number(_totalPrice)+(Number(_quantity)*Number(vDeliveryCharge))).toString()), _invoiceNo[0]?.invoiceprefix + _invoiceNo[0]?.InvNumber,
            raz_paymentId, memberId, randomNo, 0, postDataObject).then(res => {
                var _strOrderId = res?.data.split('|');
                insertSalesOrderItems(_strOrderId[0], memberId);

            })
            .catch(() => {
                navigate(ROUTES.DASHBOARD.Failed_PAGE);
            });


    }

    const insertSalesOrderItems = (_strOrderId: any,memberId : any) => {debugger;
        var vDeliveryCharge=localStorage.getItem('deliveryCharges');
        const productDetails = [{
            Title: _name,
            Price: _variancePrice,
            ProductId: _productId,
            Picture: "",
            Qty: _quantity,
            DiscountDetailsId: 0,
            TaxDetailsId: _taxDeatailsId,
            CouponId: 0,
            ImageURL: _imgUrl,
            Discount: 0,
            Tax: 0,
            CopounCode: "",
            CopounPercentage: 0,
            CopounAmount: 0,
            LineTotal: Number(_totalPrice)+(Number(_quantity)*Number(vDeliveryCharge)),
            VarianceId: _productVarianceId,
            VarienceType: _varianceType,
            GSTPercentage: "",
            MrpPrice: _variancePrice,
            DiscountAmt: 0,
            BuynNumber: 0,
            GetnNumber: 0,
            OfferMessage: "-",
            Subcategoryid: _subcatogoryId,
            $$hashKey: ""
        }];

        const postrecords = localStorage.getItem('postData');
        var postDataObject;
        if (postrecords !== null) {
            postDataObject = JSON.parse(postrecords);
        }
        if(memberId===undefined || memberId===null || memberId==="")
        {
            memberId=localStorage.getItem("MemberId"); 
        }
        InsrtSalesOrderItem(postDataObject?.AddressLine1, postDataObject?.AddressLine2, postDataObject?.City, postDataObject?.EmailId,
            memberId, postDataObject?.Landmark, postDataObject?.ContactNo, postDataObject?.State, postDataObject?.PinCode, "C",
            _strOrderId, productDetails).then(responce => {
                debugger;
                if(memberId===undefined || memberId===null || memberId==="")
                {
                    memberId=localStorage.getItem("MemberId"); 
                }
                SendInvoiceMail(4, "User", ((Number(_totalPrice)+Number(vDeliveryCharge)).toString()), memberId, _invoiceNo[0].invoiceprefix + _invoiceNo[0].InvNumber,
                    _companyDet?.CompanyName, "chinnappaganesan.com", productDetails).then(res => {

                    })
                    .catch(() => {
                        navigate(ROUTES.DASHBOARD.Failed_PAGE);
                    });
                BindOrderDetails(_strOrderId).then(res => {
                    _setproductOrderDetails(res?.data);
                });

                localStorage.removeItem('_totalPrice');
                localStorage.removeItem('_quantity');
                localStorage.removeItem('_Productname');
                localStorage.removeItem('_productId');
                localStorage.removeItem('_taxDetailsId');
                localStorage.removeItem('_imageUrl');
                localStorage.removeItem('_productVarianceId');
                localStorage.removeItem('_productPrice');
                localStorage.removeItem('_varianceType');
                localStorage.removeItem('_subcatogoryId');
                localStorage.removeItem('deliveryCharges');
                localStorage.removeItem('_Cartquantity')
                localStorage.setItem("Quantity", base64.encode(JSON.stringify({ Quantity: 0, name: "" })));
                const _tempData = cartQuanty.cartQuantity();
                dispatch({
                    type: "SET_Quantity",
                    Cart: _tempData
                });
                navigate(ROUTES.DASHBOARD.Thankyou_PAGE + "?orderId=" + _strOrderId);
            })
            .catch(() => {
                navigate(ROUTES.DASHBOARD.Failed_PAGE);
            });

    }


    const getCompanyDetails = () => {
        GetCompanyDetails()
            .then(res => {
                _setCompanyDet(res?.data[0]);
            })
    }

    const invoiceNumber = () => {
        GetInvoiceNumber().then(res => {
            _setInvoiceNum(res?.data);
        })
    }

    const getPurchaseDet = () => {
        const TotalPrice: string | null = localStorage.getItem('_totalPrice');
        const Quantity: string | null = localStorage.getItem('_quantity');
        const Name: string | null = localStorage.getItem('_Productname');
         const email: any = localStorage.getItem('app_login');
        const productId: any = localStorage.getItem('_productId');
        const taxDetailsId: any = localStorage.getItem('_taxDetailsId');
        const imgUrl: any = localStorage.getItem('_imageUrl');
        const _productVarianceId: any = localStorage.getItem('_productVarianceId');
        const _productPrice: any = localStorage.getItem('_productPrice');
        const _varianceType: any = localStorage.getItem('_varianceType');
        const Subcategoryid: any = localStorage.getItem('_subcatogoryId');


        setEmailId(email);
        _setTotalPrice(TotalPrice);
        _seQuantity(Quantity);
        _setName(Name);
        _setproductId(productId);
        _settaxDeatailsId(taxDetailsId);
        _setimgUrl(imgUrl);
        _setvariancePrice(_productPrice);
        _setProductVarianceId(_productVarianceId);
        _setVarianceType(_varianceType);
        _setsucatogoryId(Subcategoryid);
        var vDelCharge=0;
        if(Number(Quantity)>=5 && Number(Quantity)<10){
            if(postData?.State==1 || postData?.State==29)
            {
                _setdelCharge(30);vDelCharge=30;
            }
            else{
                _setdelCharge(50);vDelCharge=50;
            }
        }
        else if(Number(Quantity)>=10){
            if(postData?.State==1 || postData?.State==29)
            {
                _setdelCharge(25);vDelCharge=25;
            }
            else{
                _setdelCharge(50);vDelCharge=50;
            }
        }
        else{
            if(postData?.State==1 || postData?.State==29)
            {
                _setdelCharge(50);vDelCharge=50;
            }
            else{
                _setdelCharge(100);vDelCharge=100;
            }
        }

        localStorage.setItem('deliveryCharges',vDelCharge.toString());

    }

    // const userDet = () => {
    //     MemberDetails(email).then(res => {
    //         _setmemberDetails(res?.data[0]?.MemberId);
    //         console.log(res?.data[0]?.MemberId)
    //     })
    // }

    const [postData, _setpostData] = useState<any>({
        AddressLine1: "",
        AddressLine2: "",
        AddressLine11: "",
        AddressLine21: "",
        City: "",
        City1: "",
        ContactNo: "",
        ContactNo1: "",
        EmailId: "",
        EmailId1: "",
        Landmark: "",
        Landmark1: "",
        MemberName: "",
        MemberName1: "",
        PinCode: "",
        PinCode1: "",
        State: "",
        State1: ""
    })


    const getPaymentGatewayConfig = () => {
        GetPG_COnfiguration()
            .then(res => {
                _setPgConfig(res?.data[0]?.PaymentGatewayConfig)
                _setPgSecret(res?.data[0]?.PaymentGatewaySecret)
            })
    }

    const updateMemberDetails = () => {
        updateMemberDet(_memberid, postData).then(res => {
            if (res?.data == "Success") {
                localStorage.setItem('postData', JSON.stringify(postData));
            }
            else {
                Swal.fire({
                    // title: 'Success!',
                    text: 'Please enter the Valid address',
                    // icon: 'warning',
                    confirmButtonText: 'OK',
                });
            }
        })
    }

    const payment = (e: any) => {
        debugger
        // if(Number(_quantity)>=5 && Number(_quantity)<10){
        //     if(postData?.State==1 || postData?.State==29)
        //     {
        //         _setdelCharge(30);
        //     }
        //     else{
        //         _setdelCharge(50);
        //     }
        // }
        // else if(Number(_quantity)>=10){
        //     if(postData?.State==1 || postData?.State==29)
        //     {
        //         _setdelCharge(25);
        //     }
        //     else{
        //         _setdelCharge(50);
        //     }
        // }
        // else{
        //     if(postData?.State==1 || postData?.State==29)
        //     {
        //         _setdelCharge(50);
        //     }
        //     else{
        //         _setdelCharge(100);
        //     }
        // }


        e.preventDefault();
        const isValid = checkValidation();

        if (!isValid) {
            return;
        }
        debugger
        if (Email?.Email == null) {
            Swal.fire({
                text: 'Please Login..!',
                // icon: 'warning',
                confirmButtonText: 'OK',
            });
        }
        else {

            updateMemberDetails();
            RazorPay( (Number(_totalPrice)+(Number(_quantity)*Number(_delCharge))).toString(), _pgConfig, _pgSecret).then(res => {
                var vOrderId = res?.data.split('|')
                handlePayment(vOrderId[0]);
            })
        }

    }

    const changeFormData = (name: string, value: any) => {
        _setpostData({ ...postData, [name]: value });

        if(name==="State"){
            var vDelCharge=0
            if(Number(_quantity)>=5 && Number(_quantity)<10){
                if(value==1 || value==29)
                {
                    _setdelCharge(30);vDelCharge=30;
                }
                else{
                    _setdelCharge(50);vDelCharge=50;
                }
            }
            else if(Number(_quantity)>=10){
                if(value==1 || value==29)
                {
                    _setdelCharge(25);vDelCharge=25;
                }
                else{
                    _setdelCharge(50);vDelCharge=50;
                }
            }
            else{
                if(value==1 || value==29)
                {
                    _setdelCharge(50);vDelCharge=50;
                }
                else{
                    _setdelCharge(100);vDelCharge=100;
                }
            }
            localStorage.setItem('deliveryCharges',vDelCharge.toString());
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);

        if (event.target.checked == true) {
            _setpostData({
                ...postData,
                AddressLine11: postData?.AddressLine1,
                AddressLine21: postData?.AddressLine2,
                City1: postData?.City,
                ContactNo1: postData?.ContactNo,
                EmailId1: postData?.EmailId,
                Landmark1: postData?.Landmark,
                MemberName1: postData?.MemberName,
                PinCode1: postData?.PinCode,
                State1: postData?.State
            })
        }
        else {
            _setpostData({
                ...postData,
                AddressLine11: "",
                AddressLine21: "",
                City1: "",
                ContactNo1: "",
                EmailId1: "",
                Landmark1: "",
                MemberName1: "",
                PinCode1: "",
                State1: "1"
            })
        }
    };

    const getAllStateList = () => {
        GetStateList().then(res => {
            _setStateList(res?.data);
        })
    }



    useEffect(() => {
        invoiceNumber();
        getPurchaseDet();
        // userDet();
        getPaymentGatewayConfig();
        getCompanyDetails();
        getAllStateList();
        
        MemberDetails(Email?.Email).then(res => {
           
            //var memId = res?.data[0]?.MemberId;    
            _setMemberId(res?.data[0]?.MemberId);
            localStorage.setItem("MemberId",res?.data[0]?.MemberId); 
            EditMemberProfile(res?.data[0]?.MemberId).then(response=>{
                
                _setpostData({...postData,["AddressLine1"]: response?.data[0]?.AddressLine1,
                    ["AddressLine2"]: response?.data[0]?.AddressLine2,
                    ["City"]: response?.data[0]?.City,
                    ["ContactNo"]: response?.data[0]?.ContactNo,
                    ["EmailId"]: response?.data[0]?.EmailId,
                    ["MemberName"]: response?.data[0]?.MemberName,
                    ["PinCode"]: response?.data[0]?.PinCode,
                    ["State"]: response?.data[0]?.State,
                })
                const Quantity: string | null = localStorage.getItem('_quantity');
                var vDelCharge=0;
                if(Number(Quantity)>=5 && Number(Quantity)<10){
                    if(response?.data[0]?.State==1 || response?.data[0]?.State==29)
                    {
                        _setdelCharge(30);vDelCharge=30;
                    }
                    else{
                        _setdelCharge(50);vDelCharge=50;
                    }
                }
                else if(Number(Quantity)>=10){
                    if(response?.data[0]?.State==1 || response?.data[0]?.State==29)
                    {
                        _setdelCharge(25);vDelCharge=25;
                    }
                    else{
                        _setdelCharge(50);vDelCharge=50;
                    }
                }
                else{
                    if(response?.data[0]?.State==1 || response?.data[0]?.State==29)
                    {
                        _setdelCharge(50);vDelCharge=50;
                    }
                    else{
                        _setdelCharge(100);vDelCharge=100;
                    }
                }
                localStorage.setItem('deliveryCharges',vDelCharge.toString());
            })
        })

    }, [])

    return (<>
        <div className='container'>
            <div className="row my-4">
                <div className="col-md-6 pt-4 pt-4">

                    <div><h5 className="fw-bold fs-5">Billing Address</h5></div>
                    <div className="row">
                        <div className="col mb-3">
                            <label className="mb-2 fs-7">Name</label>
                            <TextField type="text" size="small" className="form-control" value={postData?.MemberName} onChange={(e: any) => changeFormData("MemberName", e.target.value)}
                                error={_validate.MemberName.error} helperText={_validate.MemberName.message} />

                        </div>
                        <div className="col mb-3">
                            <label className="mb-2 fs-7">Email</label>
                            <TextField type="email" size="small" className="form-control" value={postData?.EmailId} onChange={(e: any) => changeFormData("EmailId", e.target.value)}
                                error={_validate.EmailId.error} helperText={_validate.EmailId.message} />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-3">
                            <label className="mb-2 fs-7">Mobile</label>
                            <TextField type="number" size="small" className="form-control verify" value={postData?.ContactNo} onChange={(e: any) => changeFormData("ContactNo", e.target.value)}
                                error={_validate.ContactNo.error} helperText={_validate.ContactNo.message} />

                        </div>
                        <div className="col mb-3">
                            <label className="mb-2 fs-7">Address 1</label>
                            <TextField type="text" size="small" className="form-control verify" value={postData?.AddressLine1} onChange={(e: any) => changeFormData("AddressLine1", e.target.value)}
                                error={_validate.AddressLine1.error} helperText={_validate.AddressLine1.message} />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-3">
                            <label className="mb-2 fs-7">Address 2</label>
                            <TextField type="text" size="small" className="form-control verify" value={postData?.AddressLine2} onChange={(e: any) => changeFormData("AddressLine2", e.target.value)}
                                error={_validate.AddressLine2.error} helperText={_validate.AddressLine2.message} />

                        </div>
                        <div className="col mb-3">
                            <label className="mb-2 fs-7">State</label>
                            <TextField
                                select
                                size="small"
                                className="form-control verify"
                                // value={postData?.DesignationMasterId}
                                onChange={(e: React.ChangeEvent<{ value: unknown }>) => changeFormData("State", e.target.value)}
                                value={postData?.State} 
                                error={_validate.State.error} helperText={_validate.State.message} >
                                <MenuItem value="">Select State</MenuItem>
                                {_stateList.length > 0 &&
                                    _stateList.map((option: any, index: number) => (
                                        <MenuItem key={index} value={option?.State_Id}>
                                            {option?.StateName}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-3">
                            <label className="mb-2 fs-7">City</label>
                            <TextField type="text" size="small" className="form-control verify" value={postData?.City} onChange={(e: any) => changeFormData("City", e.target.value)}
                                error={_validate.City.error} helperText={_validate.City.message} />

                        </div>
                        <div className="col mb-3">
                            <label className="mb-2 fs-7">Pin Code</label>
                            <TextField type="number" size="small" className="form-control verify" value={postData?.PinCode} onChange={(e: any) => changeFormData("PinCode", e.target.value)}
                                error={_validate.PinCode.error} helperText={_validate.PinCode.message} />

                        </div>
                    </div>
                    <div>
                        <h5 className="fw-bold fs-5">Delivery Address</h5>
                        <div className="d-flex">
                            <Checkbox
                                className="m-0 p-0"
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <Typography className="fs-7 my-auto">Same as Billing Address</Typography>
                        </div>
                    </div>

                    <div>
                    <div className="row">
                        <div className="col mb-3">
                            <label className="mb-2 fs-7">Name</label>
                            <TextField type="text" size="small" className="form-control" value={postData?.MemberName1} onChange={(e: any) => changeFormData("MemberName1", e.target.value)}
                                error={_validate.MemberName1.error} helperText={_validate.MemberName1.message} />

                        </div>
                        <div className="col mb-3">
                            <label className="mb-2 fs-7">Email</label>
                            <TextField type="email" size="small" className="form-control" value={postData?.EmailId1} onChange={(e: any) => changeFormData("EmailId1", e.target.value)}
                                error={_validate.EmailId1.error} helperText={_validate.EmailId1.message} />

                        </div>
                    </div>
                        <div className="row py-2">
                        <div className="col mb-3">
                            <label className="mb-2 fs-7">Mobile</label>
                            <TextField type="number" size="small" className="form-control verify"  value={postData?.ContactNo1} onChange={(e: any) => changeFormData("ContactNo1", e.target.value)}
                                error={_validate.ContactNo1.error} helperText={_validate.ContactNo1.message} />

                        </div>
                            <div className="col mb-3">
                                <label className="mb-2 fs-7">Address 1</label>
                                <TextField type="text" size="small" value={postData?.AddressLine11} className="form-control verify" onChange={(e: any) => changeFormData("AddressLine11", e.target.value)}
                                    error={_validate.AddressLine11.error} helperText={_validate.AddressLine11.message} />

                            </div>
                          
                        </div>
                        <div className="row">
                        <div className="col mb-3">
                                <label className="mb-2 fs-7">Address 2</label>
                                <TextField type="text" size="small" className="form-control verify" value={postData?.AddressLine21} onChange={(e: any) => changeFormData("AddressLine21", e.target.value)}
                                    error={_validate.AddressLine21.error} helperText={_validate.AddressLine21.message} />

                            </div>
                        <div className="col mb-3">
                            <label className="mb-2 fs-7">State</label>
                            <TextField
                                select
                                size="small"
                                className="form-control verify"
                                 value={postData?.State1}
                                onChange={(e: React.ChangeEvent<{ value: unknown }>) => changeFormData("State1", e.target.value)}
                                error={_validate.State1.error} helperText={_validate.State1.message} 
                                >
                                <MenuItem value="">Select State</MenuItem>
                                {_stateList.length > 0 &&
                                    _stateList.map((option: any, index: number) => (
                                        <MenuItem key={index} value={option?.State_Id}>
                                            {option?.StateName}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </div>
                    </div>
                        <div className="row">
                            <div className="col mb-3">
                                <label className="mb-2 fs-7">City</label>
                                <TextField type="text" size="small" className="form-control verify" value={postData?.City1} onChange={(e: any) => changeFormData("City1", e.target.value)}
                                    error={_validate.City1.error} helperText={_validate.City1.message} />

                            </div>
                            <div className="col mb-3">
                                <label className="mb-2 fs-7">Pin Code</label>
                                <TextField type="number" size="small" className="form-control verify" value={postData?.PinCode1} onChange={(e: any) => changeFormData("PinCode1", e.target.value)}
                                    error={_validate.PinCode1.error} helperText={_validate.PinCode1.message} />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-1 pt-4">
                </div>

                <div className="col-md-5 pt-4 mt-4 mb-3">
                    <div className="border border-3 rounded p-5">
                        <div><h5 className="fw-bold text-center fs-5">Your Order</h5></div>

                        <div className="row my-4 d-flex justify-content-between">
                            <div className="row">
                                <div className="col mb-3">
                                    <h6>Product Name :</h6>
                                </div>
                                <div className="col mb-3  text-center">
                                    <h6>{_name}</h6>
                                </div>

                                <div className="row">
                                    <div className="col mb-3">
                                        <h6>Price :</h6>
                                    </div>
                                    <div className="col mb-3 text-center">
                                        <h6>{'₹' + " " + _totalPrice}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-3">
                                        <h6>Quantity :</h6>
                                    </div>
                                    <div className="col mb-3 text-center">
                                        <h6>{"x " + _quantity}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-3">
                                        <h6>Discount :</h6>
                                    </div>
                                    <div className="col mb-3 text-center">
                                        <h6>{'₹' + " " + 0}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-3">
                                        <h6>Delivery Charge :</h6>
                                    </div>
                                    <div className="col mb-3 text-center">
                                        <h6>{'₹' + " " +(Number(_quantity)*Number(_delCharge)).toString() }</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col mb-3">
                                        <h6 className="pt-2 fw-bold">Total :</h6>
                                    </div>
                                    <div className="col mb-3 text-center">
                                        <h6 className="pt-2 fw-bold">{'₹' + " " + (Number(_totalPrice)+((Number(_quantity)*Number(_delCharge)))).toString()}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 text-center" style={{ cursor: "pointer" }} >
                                <Button variant="contained" className={clsx('bg-warning', 'px-6', 'py-2', 'text-dark')} onClick={payment}>Pay Now</Button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>)
}
