import React from 'react'
import { useState, useEffect } from 'react';

const Countdown = ({min, sec, timeUp}:any) => {
    const [ minutes, setMinutes ] = useState(min);
    const [seconds, setSeconds ] =  useState(sec);
    useEffect(()=>{
    let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                    timeUp();
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
          };
    });

    return (
        <div>
        { minutes === 0 && seconds === 0
            ? null
            : <h6 className="text-blue my-2"> Resend OTP after <span className="text-danger">{minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</span> sec</h6> 
        }
        </div>
    )
}

export default Countdown;