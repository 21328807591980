import { base64 } from './HelperService';


export const insertUpdateLocalStorage = (storeKey: string, data: any) => {
    const _temp: any = JSON.parse(localStorage.getItem(storeKey) || 'null');
    localStorage.setItem(storeKey, JSON.stringify({ ..._temp, ...data }));
}
export const getLocalStorage = (storeKey: string) => {
    return JSON.parse(localStorage.getItem(storeKey) || 'null');
}

export const removeLocalStorage = (storeKey: string) => {
    localStorage.removeItem(storeKey);
}

export const cartQuanty = {
    setQuantity() {
        const _tempData = {};
        return _tempData;
    },
    removeQuantity() {
        localStorage.removeItem('Quantity');
        return null;
    },
    cartQuantity() {
        const _tempData: string | null = localStorage.getItem('Quantity');
        if (!_tempData) return null;
        const QuantityData = JSON.parse(base64.decode(_tempData));
        console.log(_tempData);

        return {
            Quantity: QuantityData.Quantity,
            name: QuantityData.name
        }
    }
}


export const UserDetails = {
    SpeechSynthesisUtterance() {
        const _tempData = {};
        return _tempData;
    },
    removeUser() {
        localStorage.removeItem('app_login');
        return null;
    },
    userDet() {
        const _tempData: string | null = localStorage.getItem('app_login');
        if (!_tempData) return null;
        const UserData = JSON.parse(base64.decode(_tempData));
debugger;
        return {
            Email: UserData.Email
           // MemberId : UserData.MemberId
        }
    }
}