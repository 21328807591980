import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import AxiosProvider from '../providers/AxiosProvider';
import LoaderControl from "../components/helpers/loaderControl";
import { ROUTES } from '../configs/constants';
import AuthLayout from '../components/layouts/authLayout';
import DashboardLayout from '../components/layouts/dashboardLayout';
import Registration from '../pages/auth/registration';
import CheckoutPage from '../pages/home/checkoutPage';
import ThankYou from '../pages/home/thankYou';
import ErrorPage from '../pages/home/errorPage';


const Home = lazy(() => import('../pages/home'));
const NotFound = lazy(() => import('../pages/notFound'));
const CHECKOUT = lazy(() => import('../pages/home/checkoutPage'));
const THANKYOU = lazy(() => import('../pages/home/thankYou'));
const Failed = lazy(() => import('../pages/home/errorPage'));


function RouteApp() {
    return (<>
        <AxiosProvider />
        <Routes>
            {/* Login and SingUp, mobile and email verify pages */}
            <Route path={ROUTES.LOGIN} element={<AuthLayout />}>
                <Route path={ROUTES.LOGIN} element={<Suspense fallback={<LoaderControl />}><Registration /></Suspense>} />
            </Route>
            <Route path={ROUTES.DASHBOARD.HOME} element={<DashboardLayout />}>
                <Route path={ROUTES.DASHBOARD.HOME} element={<Suspense fallback={<LoaderControl />}><Home /></Suspense>} />
            </Route>
            <Route path={ROUTES.DASHBOARD.HOME} element={<DashboardLayout />}>
                <Route path={ROUTES.DASHBOARD.CHECKOUT_PAGE} element={<Suspense fallback={<LoaderControl />}><CheckoutPage /></Suspense>} />
            </Route>
            <Route path={ROUTES.DASHBOARD.HOME} element={<DashboardLayout />}>
                <Route path={ROUTES.DASHBOARD.Thankyou_PAGE} element={<Suspense fallback={<LoaderControl />}><ThankYou /></Suspense>} />
            </Route>
            <Route path={ROUTES.DASHBOARD.HOME} element={<DashboardLayout />}>
                <Route path={ROUTES.DASHBOARD.Failed_PAGE} element={<Suspense fallback={<LoaderControl />}><ErrorPage /></Suspense>} />
            </Route>
            <Route path={"*"} element={<Suspense fallback={<LoaderControl />}><NotFound /></Suspense>} />
        </Routes>
    </>)
}

export default RouteApp;
