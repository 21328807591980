import { UserDetails } from "./AuthService";
import { generateUniqueId, userSession,cartSession } from "./HelperService";

export const initialState = {
    user: userSession(),
    Email: UserDetails?.userDet(),
    cartItems:cartSession(),
};

const Reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.user
            }
        case 'SET_USEREMAIL':
            return {
                ...state,
                Email: action.Email
                // MemberId :action.MemberId
            }
        case 'SET_Quantity':
            return {
                ...state,
                cartItems: action.Cart
            }
        default:
            return state;
    }
};

export default Reducer;
