import * as React from "react";
import { httpGet, httpPost, httpPut } from "../services/HttpService";
import { ROUTES } from "../configs/constants";
import { get } from "http";


// Write API's functions below
export const GetContactUsDetails = (domain: any) => {
    return httpGet(ROUTES.API.GET_CONTACT_US_DETAILS + '?Domain=' + domain)
}
export const GetToken = () => {
    return httpPost(ROUTES.API.GENERATE_TOKEN)
}
export const GetSocialMediaLink = () => {
    return httpGet(ROUTES.API.GET_SOCIAL_MEDIA)
}
export const GetSiteConfig = () => {
    return httpGet(ROUTES.API.GET_SITE_CONFIGURATION)
}

export const GetCompanyDetails = () => {
    return httpGet(ROUTES.API.GetCompayDetails)
}

export const InsrtSalesOrder = (Domain: any, OrderPlacedDevice: any, PaymentType: any,
    PhoneNo: any,Subtotal : any, Weight: any, decDeliveryCharge: any, decTotalAmount: any, invoiceNo: any,
    razorpay_payment_id: any, strMemberId: any, strOrderNo: any, vCoupounAmt: any, formData: any) => {

    return httpPost(ROUTES.API.InsrtSalesOrder + "?Domain=" + Domain + "&OrderPlacedDevice=" + OrderPlacedDevice
        + "&PaymentType=" + PaymentType + "&PhoneNo=" + PhoneNo + "&Weight=" + Weight + "&Subtotal=" + Subtotal + "&decDeliveryCharge=" +
        decDeliveryCharge + "&decTotalAmount=" + decTotalAmount + "&invoiceNo=" + invoiceNo + "&razorpay_payment_id=" +
        razorpay_payment_id + "&strMemberId=" + strMemberId + "&strOrderNo=" + strOrderNo + "&vCoupounAmt=" +
        vCoupounAmt, formData)
}

export const InsrtSalesOrderItem = (MemberAddressLine1: any, MemberAddressLine2: any, MemberCity: any,
    MemberEmailID: any, MemberId: any, MemberLandamark: any, MemberPhoneNumber: any, MemberState: any,
    MemberZipCode: any, PaymentType: any, strOrderId: any, formData: any) => {

    return httpPost(ROUTES.API.InsrtSalesOrderItems + "?MemberAddressLine1=" + MemberAddressLine1 + "&MemberAddressLine2=" + MemberAddressLine2
        + "&MemberCity=" + MemberCity + "&MemberEmailID=" + MemberEmailID + "&MemberId=" + MemberId + "&MemberLandamark=" + MemberLandamark
        + "&MemberPhoneNumber=" + MemberPhoneNumber + "&MemberState=" + MemberState + "&MemberZipCode=" + MemberZipCode
        + "&PaymentType=" + PaymentType + "&strOrderId=" + strOrderId, formData)
}

export const SendInvoiceMail = (LanguageID: any, OrderedBy: any, decTotalAmount: any, strMemberId: any,
    strOrderNo: any, vCompanyName: any, vDomain: any, formData: any) => {

    return httpPost(ROUTES.API.Send_Invoice_Mail + `?LanguageID=${LanguageID}&OrderedBy=${OrderedBy}&decTotalAmount=${decTotalAmount}&strMemberId=${strMemberId}&strOrderNo=${strOrderNo}&vCompanyName=${vCompanyName}&vDomain=${vDomain}`, formData)
}

export const GetInvoiceNumber = () => {
    return httpGet(ROUTES.API.GetInvoiceNumber + `?CompanyId=1`);
}

export const BindOrderDetails = (strOrderId : any) => {
    return httpGet(ROUTES.API.Bind_Order_Details + `?strOrderId=${strOrderId}`);
}

export const verifyLoginOtp = (emailId: string, otp: string) => {
    return httpGet(ROUTES.API.VERIFY_LOGIN_OTP + `?mobile=${emailId}&otp=${otp}`);
}

export const getLoginOtp = (emailId: string) => {
    return httpGet(ROUTES.API.SEND_OTP + `?mobile=${emailId}`);
}

export const RazorPay = (amount: string,PaymentGatewayKey:string,PaymentGatewaySecret:string) => {
    return httpPost(ROUTES.API.RazorPay_Payment + `?TotalAmount=${amount}&PaymentGatewayKey=${PaymentGatewayKey}&PaymentGatewaySecret=${PaymentGatewaySecret}`);
}

export const MemberDetails = (Emailid: string) => {
    return httpGet(ROUTES.API.GET_MEMBER_DETAILS + `?MobileNo=${Emailid}`);
}

export const EditMemberProfile = (MemberId: string) => {
    return httpGet(ROUTES.API.EditMemberProfile + `?MemberId=${MemberId}`);
}

export const updateMemberDet = (memberId: string, formData: any) => {
    return httpPost(ROUTES.API.UpdateMember_Address + `?MemberId=${memberId}`, formData);
}

export const GetAllSubCategory = (Langauge: any, MemberId: any) => {
    return httpGet(ROUTES.API.GETALL_SUBCATOGORY + "?Langauge=" + Langauge + "&MemberId=" + MemberId)
}

export const GetPG_COnfiguration = () => {
    return httpGet(ROUTES.API.GET_PG_CONFIG)
}

export const GetStateList = () => {
    return httpGet(ROUTES.API.GetAllStateById + '?CountryID=1&Status=1')
}