import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { ArrowBackRounded, CheckCircle } from '@mui/icons-material';
import { useStateValue } from '../../providers/StateProvider';
import { ROUTES } from '../../configs/constants';
import { IMAGES_ICON } from '../../assets/images/exportImages';

export default function ThankYou({ content = "Your order is placed", buttonName = 'Close', navigateTo = "" }: any) {
    const [{ user }]: any = useStateValue()
    console.log(user)
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  


    const _orderId = queryParams.get('orderId');

    const [_loading, _setLoading] = useState<any>(false);

    const goBack = () => {
        debugger;
        navigate(ROUTES.DASHBOARD.HOME)
    }

    useEffect(() => {
        // const tempObj = getLocalStorage('addProperty');
        // _setFormData({ propertyType: tempObj?.propertyType })
    }, []);

    return <React.Fragment>
        <div className="thankYouBg vh-100">
            <div className="thankYouBg">
                <div className="col-10 mx-auto row align-items-center justify-content-center">
                    <div className="text-center my-5">
                        {/* <img className="" src={IMAGES_ICON.AuthorImage} alt="icons" draggable={false} height={45} /> */}
                        <div><CheckCircle className="mt-5" style={{ fontSize: "68px", color: "#05bf68", }} /></div>
                        <div className="fs-4 fw-bold">Thank you!</div>

                    </div>
                    <div className="">
                        <div className="row justify-content-center">
                            {content} , Order Number is : {_orderId}
                        </div>
                    </div>
                    <div className='d-flex justify-content-center gap-4 mobJustify my-3'>
                        <LoadingButton className="btn btn-block backBlackButton" endIcon={<></>} variant="contained" loadingPosition="end"
                            loading={_loading} onClick={goBack} style={{color : "white"}}>{buttonName}</LoadingButton>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}
