import FooterIcon from './Footer Cartyogi Logo.png';
import CallIcon from './Call Icon.png';
import MailIcon from './Mail Icon.png';
import PlayIcon from './News Coverage Icon.png';
import NewsCoverage1 from './News Coverage 1.png';
import NewsCoverage2 from './News Covergae_2.png';
import NewsCoverage3 from './News Coverage_3.png';
import TwitterIcon from './Twitter.png';
import FacebookIcon from './Facebook.png';
import YoutubeIcon from './Youtube.png';
import YoutubeThumbnailIcon from './Youtube Thumbnail.png';
import UpperQuoteIcon from './Upper Quote.png';
import LowerQuoteIcon from './Lower Quote.png';
import MyCartIcon from './My Cart Icon.png';
import BookImage from './Book Image.png';
import AuthorImage from './Author Image.png';
import Testimonial from './Testimonial Image_1.png';

export const IMAGES_ICON = {
    FooterIcon, CallIcon, MailIcon, PlayIcon, NewsCoverage1, NewsCoverage2,
    NewsCoverage3, TwitterIcon, FacebookIcon, YoutubeIcon, YoutubeThumbnailIcon,
    UpperQuoteIcon, LowerQuoteIcon, MyCartIcon, BookImage, AuthorImage, Testimonial,
}