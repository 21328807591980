const baseRoute = '/';
export let baseApi = 'https://chinnappaganesanwebapi.cartyogi.com/api/';
if (process.env.NODE_ENV === "production") {
    baseApi = "https://chinnappaganesanwebapi.cartyogi.com/api/";

}

export const ROUTES = {

    // HOME: baseRoute,
    HOME: baseRoute + '',
    LOGIN: baseRoute + 'login',
    DASHBOARD: {
        HOME: baseRoute + '',
        DRAG_SELECTION: baseRoute + 'drag-selection',
        CHECKOUT_PAGE: baseRoute + 'checkout',
        Thankyou_PAGE: baseRoute + 'thankyou',
        Failed_PAGE: baseRoute + 'failed'
    },

    API: {
        GET_CONTACT_US_DETAILS: baseApi + 'AdminIndex/GetBusinessdetails',
        GENERATE_TOKEN: baseApi + 'IndexPage/TokenGenerationForApp',
        GET_SOCIAL_MEDIA: baseApi + 'IndexPage/GetSocialMediaLink',
        GET_SITE_CONFIGURATION: baseApi + 'IndexPage/GetSiteSettingConfiguration',
        GETALL_SUBCATOGORY: baseApi + 'IndexPage/GetAllSubCategory',
        SEND_OTP : baseApi + 'adminOP/getOTP',
        VERIFY_LOGIN_OTP : baseApi + 'adminOP/VerifyOTP',
        GetCompayDetails : baseApi + 'SalesDetail/GetCompanyDetails',
        InsrtSalesOrder : baseApi + 'SalesDetail/InsertSalesOrder',
        InsrtSalesOrderItems : baseApi + 'SalesDetail/InsertSalesOrderItems',
       // RazorPay_Payment : baseApi + 'CartPayment/RazsorPayTest_Production',
       RazorPay_Payment : baseApi + 'CartPayment/RazsorPayTest_Production',

        UpdateMember_Address : baseApi + 'ProductDetail/UpdateMemberAddress',
        GET_MEMBER_DETAILS : baseApi + 'IndexPage/GetMemberDetailsMobileNo',
        GET_PG_CONFIG : baseApi + 'PaymentCheckout/GetPaymentGatewayConfiguration',
        Send_Invoice_Mail : baseApi + 'SalesDetail/SendInvoiceMail',
        GetInvoiceNumber : baseApi + 'InvoiceNumber/GetInvoiceNumber',
        Bind_Order_Details : baseApi + 'SalesInvoice/BindOrderDetailForInvoicePage',
        GetAllStateById : baseApi +  'Onboarding/GetStatelist',
        EditMemberProfile : baseApi + 'IndexPage/EditMemberProfile'
    },

};